<template>
  <dialog-outline v-model="showDialog">
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- display prompt -->
      <div v-show="visiblePrompt">
        <br />
        <div v-if="isSuspendOperation">
          <v-textarea
            v-model="suspensionMessage"
            outlined
            :label="suspensionLabel"
          ></v-textarea>
        </div>
        <div v-else>
          <base-prompt :prompt="createPrompt()"></base-prompt>
        </div>
      </div>

      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>

      <!-- display Progress -->
      <progress-display v-if="visibleProgress">
        {{ progressText }}
      </progress-display>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button
        @click="onOk"
        :disabled="!isActionEnabled"
        :color="promptColorClass"
        >{{ dialogOkCommandLabel }}</base-button
      >
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import { createActionResultSuccess } from "@/model/action/actionModel";
import {
  findProjectOperation,
  projectOperation as wfProjectOperation
} from "@/model/workflow/project/projectModel";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";

export default {
  name: "ProjectOperationDialog",
  mixins: [dialogOutlineMixin, promptAbleMixin, progressAbleMixin],
  props: {
    /**
     * @type {{projectId: Number, projectName:string, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string, userTasks: {taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}}
     */
    project: undefined,

    /**
     * project Operation
     */
    projectOperation: {
      default: wfProjectOperation.CancelProject,
      required: true
    },

    /**
     * Function to perform
     */
    performAction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      suspensionMessage: undefined
    };
  },
  computed: {
    /**
     * Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return findProjectOperation(this.projectOperation);
    },

    /**
     * operation Label
     * @return {string} download operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Dialog Title
     * @return {string} Formatted Dialog Title
     */
    dialogTitle() {
      return `${this.operationLabel} ${this.project?.projectName}`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${this.project?.projectName}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * Prompt text
     * remarks: overwrite promptAbleMixin.promptText
     * @return {string}
     */
    promptText() {
      return `Do you want to ${this.action}?`;
    },

    /**
     * current Prompt Type
     * remarks: overwrite promptAbleMixin.currentPromptType
     * @return {string} current Prompt Type
     */
    currentPromptType() {
      return this.promptTypeName.critical;
    },

    /**
     * Prompt Hint
     * remarks: overwrite promptAbleMixin.promptHint
     * @return {string} Prompt Hint
     */
    promptHint() {
      return `${this.action} from current repository`;
    },

    /**
     * is project Suspend Operation
     * @return {boolean}
     */
    isSuspendOperation() {
      return this.projectOperation === wfProjectOperation.SuspendProject;
    },

    /**
     * Is Action Enabled
     * @return {boolean|boolean}
     */
    isActionEnabled() {
      return this.projectOperation === wfProjectOperation.SuspendProject
        ? this.suspensionMessage?.length > 0 ?? false
        : true;
    },

    /**
     * Suspension Label Message
     * @return {string}
     */
    suspensionLabel() {
      return "Please Specify Reason for Suspension";
    }
  },
  methods: {
    async onOk() {
      try {
        this.visiblePrompt = false;

        this.clearAlert();
        this.showProgress(this.formatProgressText(this.action));

        /**
         * perform on OK Action
         */
        await this.performAction(this.suspensionMessage);

        /**
         * action Result
         * @type {{type: string, message: string, outcome: string}}
         */
        const result = createActionResultSuccess(this.action);

        await this.autoCloseDialog(result.message);
      } catch (e) {
        this.closeProgress();

        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.closeProgress();
      }
    },

    /**
     * Handle on Dialog Opened event in order to initialize data
     */
    onDialogOpened() {
      this.internalAlert = undefined;
      this.visiblePrompt = true;

      this.closeProgress();
    },

    /**
     * Handle on Dialog Closed event in order to clear data
     */
    onDialogClosed() {
      this.internalAlert = undefined;
      this.visiblePrompt = true;

      this.closeProgress();
    }
  }
};
</script>
